import Nette from '../NetteForms'
import { isPidValid, isDateInRange } from './validations'

Nette.validators.AppFormsContractOnlineFormFactory_validatePersonalIdentificationNumber = function(elem) {

    const pid = elem.value

    return isPidValid(pid)
}

Nette.validators.AppFormsContractOnlineFormFactory_validateDateRange = function(elem, range, date) {
    return isDateInRange(date, range, 'DD.MM.YYYY', 'DD.MM.YYYY')
}