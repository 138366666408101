//pokud je validace na kontrolce c.1 a vyskoci na ni chyba, ze musi byt vyplnena kontrolka c.2, tak to pri zmene kontrolky c.2 zvaliduje tu prvni
document.addEventListener('change', () => {
    const element = event.target

    const form = element.form

    if (!form) {
        return
    }

    const fields = [...form.querySelectorAll('input:not([type="submit"]), textarea, select')]

    const affectedFields = []

    fields.forEach(field => {
        const netteRules = JSON.parse(field.getAttribute('data-nette-rules') || '[]')
        if (!netteRules) {
            return
        }

        if (netteRules.some(rule => rule.control && rule.control === element.name)) {
            affectedFields.push(field)
        }
    })

    affectedFields.forEach(field => {
        //pripadne se muze smazat podminka, ale pak to validuje treba inputy nekde vepredu, ke kterym se clovek jeste nemusel dostat
        if (field.classList.contains('has-error')) {
            Nette.validateControl(field)
        }
    })
})