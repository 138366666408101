import moment from 'moment'

export const isPidValid = pid => {

    // dle https://phpfashion.com/jak-overit-platne-ic-a-rodne-cislo
    // a https://forum.nette.org/cs/23381-vlastni-javascriptova-validace#p157380

    const rawPid = pid.replace('/', ''),
        regex = /(\d\d)(\d\d)(\d\d)(\d\d\d)(\d?)/;

    if (!rawPid.match(regex)) {
        return false;
    }

    const matches = rawPid.match(regex);

    let year 	= matches[1],
        month 	= matches[2],
        day 	= matches[3],
        ext 	= matches[4],
        c 		= matches[5];


    function checkDate(year, month, day){
        const d = new Date(year, month-1, day);
        return d.getFullYear() == year && d.getMonth()+1 == month && d.getDate() == day;
    }


    if (!c) {
        year = parseInt(year);
        year += year < 54 ? 1900 : 1800;
    } else {
        let mod = (year + month + day + ext) % 11;
        if (mod === 10) {
            mod = 0;
        }
        if (mod != parseInt(c)) {
            return false;
        }

        year = parseInt(year);
        year += year < 54 ? 2000 : 1900;
    }

    if (month > 70 && year > 2003) {
        month -= 70;
    } else if (month > 50) {
        month -= 50;
    } else if (month > 20 && year > 2003) {
        month -= 20;
    }

    return checkDate(year, month, day);
}
  
    
export const isDateInRange = (date, range, dateFormat = 'DD.MM.YYYY', rangeFormat = 'YYYY-MM-DD') => {
    if (!Array.isArray(range)) {
        range = [range];
    } else if (range.length === 0) {
        return false
    }

    const from = range[0]
    const to = (range.length !== 2) ? moment().format(rangeFormat) : range[range.length - 1]

    if (moment(from, rangeFormat).isValid() == false || moment(to, rangeFormat).isValid() == false) {
        return false
    }

    if (moment(date, rangeFormat).isValid() == false) {
        return false
    }

    if (moment(from, rangeFormat) <= moment(date, dateFormat) && moment(date, dateFormat) <= moment(to, rangeFormat)) {
        return true
    }

    return false
}